import { defineStore } from 'pinia';
import axios from 'axios';

export const useMarketStore = defineStore('market', {
	state: () => ({
		selectedMarket: null,
		selectedProject: 'na',
		markets: [],
		projects: [],
		business_account_id: 0,
		force_key: new Date().getTime() - 15 * 60 * 1000
	}),
	getters: {
		projectName(state) {
			const project = state.projects.find(p => p.id === state.selectedProject);
			return project ? project.name : null;
		},

		marketName(state) {
			const market = state.markets.find(m => m.id === state.selectedMarket);
			return market ? market.name : null;
		},

		marketNameUnderScore(state) {
			return state.marketName ? state.marketName.replace(/[\s-]+/g, '_').toLowerCase() : null;
		},
	},
	actions: {
		async fetchData() {
			const response = await axios.post(
				'vuejs/get_projects_markets',
				{
					project_id: this.selectedProject,
				},
				{ withCredentials: true },
			);

			this.projects = response.data.data.projects;
			this.markets = response.data.data.markets;

			const selectedProj = this.projects.find(p => p.id === this.selectedProject);
			if (selectedProj?.business_account_id) {
			  this.business_account_id = selectedProj.business_account_id;
			}
			

			if ((this.selectedProject === 'na' && this.projects.length > 0) || this.projects.findIndex(elem => elem.id === this.selectedProject) === -1) {
				this.selectedProject = this.projects[0].id;
			}

			if ((this.selectedMarket === null && this.markets.length > 0) || this.markets.findIndex(elem => elem.id === this.selectedMarket) === -1) {
				this.selectedMarket = this.markets[0].id;
			}

			return response.data.data;
		},

		async fetchMarket(project_id) {
			const response = await axios.post(
				'vuejs/get_markets',
				{
					project_id: project_id,
				},
				{ withCredentials: true },
			);

			return response.data.data;
		},

		async forceMarketChange(id, name) {
			const response = await axios.post(
				'vuejs/get_market_switch',
				{
					id: id,
					name: name,
				},
				{ withCredentials: true },
			);

			this.selectedMarket = response.data.data.market_id,
			this.selectedProject = response.data.data.project_id,
			this.business_account_id = response.data.data.business_account_id
			this.markets = JSON.parse(response.data.data.markets)

			this.force_key = new Date().getTime();

			return response.data.data;
		},

		selectMarket(marketId) {
			this.selectedMarket = marketId;
		},

		selectProject(projectId) {
			this.selectedProject = projectId;
			this.selectedMarket = null;
		},

		// async fetchMarketSettings(marketId) {

		//     const response = await axios.post('/vuejs/settings_page',
		//     {
		//         'market_id': marketId
		//     },
		//     { withCredentials: true });
		//     this.selectedMarketSettings = JSON.parse(response.data.market);

		//     return response.data.data;
		// }
	},
	persist: true,
});
