<template>
	<beta-section v-if="feature.status == 'beta' && checkAccess(feature) && show && checkUserRole()">
		<slot></slot>
	</beta-section>

	<div v-else-if="feature.status == 'deployed' && checkAccess(feature) && show && checkUserRole()">
		<slot></slot>
	</div>

	<alpha-section v-else-if="feature.status == 'alpha' && show && authStore.hasAccess('super_admin')">
		<slot></slot>
	</alpha-section>

	<div v-if="feature.status == 'beta' && checkAccess(feature) && !show && checkUserRole()">
		<slot></slot>
	</div>

	<div v-else-if="feature.status == 'deployed' && checkAccess(feature) && !show && checkUserRole()">
		<slot></slot>
	</div>

	<div v-else-if="feature.status == 'alpha' && !show && authStore.hasAccess('super_admin')">
		<slot></slot>
	</div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';

import AlphaSection from '@/components/utilities/alphaSection.vue';
import BetaSection from '@/components/utilities/betaSection.vue';

import { storeToRefs } from 'pinia';

import { useBetaStore } from '@/store/global_settings/beta';
import { useAuthStore } from '@/store/global/auth';

const betaStore = useBetaStore();
const { betaFeatures, selectedProjectPlan } = storeToRefs(betaStore);

const authStore = useAuthStore();

const router = useRouter();

const props = defineProps({
	featureName: String,
	show: {
		type: Boolean,
		default: true,
	},
});

let feature = ref({});

const getSelectedFeature = () => {
	if (props.featureName) {
		return betaFeatures.value.find(feature => feature.name.toLowerCase() === props.featureName.toLowerCase());
	}
};

const isFeatureInPlan = feature => {
	const plan = selectedProjectPlan.value?.plan || 'START';

	switch (plan) {
		case 'START':
			return feature.plan_start;
		case 'GROW':
			return feature.plan_grow;
		case 'EXPAND':
			return feature.plan_expand;
		case 'AGENCY':
			return feature.plan_agency;
		case 'APIONLY':
			return feature.plan_apionly;
		default:
			return false;
	}
};

const isProjectInFeature = feature => {
	// Default to an empty object if selectedProjectPlan.value is undefined
	const projectId = selectedProjectPlan.value?.project_id;

	if (!projectId) {
		return false; // No project_id, so return false
	}

	let founded = feature.projects_ids.find(project => project.id === projectId);
	return founded ? true : false;
};

const checkAccess = feature => {
	if (authStore.hasAccess('super_admin')) {
		return true;
	}

	let featureInPlan = isFeatureInPlan(feature);
	const hasAccess = featureInPlan ? true : isProjectInFeature(feature);

	const isLoggedOut = localStorage.getItem('logout') || false;

	if (router.currentRoute.value.name == 'dashboard' && selectedProjectPlan.value.plan == 'APIONLY' && !isLoggedOut) {
		router.push('/api');
	}

	return hasAccess;
};

const checkUserRole = () => {
	let role;

	try {
		role = feature.value.roles_ids.find(role => role.selected === true);
	} catch (error) {
		return true;
	}

	if (role == undefined) {
		return true;
	}

	role.name = role.name == 'super admin' ? 'super_admin' : role.name;

	return authStore.hasAccess(role.name);
};

onMounted(() => {
	if (props.featureName) {
		feature.value = getSelectedFeature();
		// featureStatus.value = feature.value.status
	}
});

watch(
	selectedProjectPlan,
	() => {
		if (selectedProjectPlan.value === undefined) {
			selectedProjectPlan.value = { plan: 'START' };
		} else {
			feature.value = getSelectedFeature();
		}
	},
	{ immediate: true },
);
</script>

<style lang="scss" scoped></style>
