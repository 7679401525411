<template>
    <svg class="icon" width="25" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M31.934 19.446A16.003 16.003 0 0 0 15.995 4.08c-.127 0-.255.002-.382.005A16.038 16.038 0 0 0 .056 20.024a15.765 15.765 0 0 0 1.807 7.36 1 1 0 0 0 .887.537h26.5a.999.999 0 0 0 .887-.538 15.962 15.962 0 0 0 1.797-7.937zM23.42 12.96l-4.926 8.62a3.697 3.697 0 0 1-5.916.857 3.695 3.695 0 0 1 .856-5.916l8.622-4.925a1 1 0 0 1 1.364 1.364zm-8.993 5.298 5.437-3.108-3.106 5.437a1.7 1.7 0 0 1-2.766.435 1.698 1.698 0 0 1 .435-2.765z" data-name="Layer 7" fill="currentColor" />
        </g>
    </svg>
</template>

<script setup>
  // Any JavaScript logic specific to this icon can go here
</script>

<style lang="scss" scoped>
.icon {
    width: 100%;
    aspect-ratio: 1/1;
    fill: currentColor; // Ensures the icon color inherits from its parent
}
</style>