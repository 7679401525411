import { defineStore } from 'pinia';
import axios from 'axios';

export const useBetaStore = defineStore('beta', {
	state: () => ({
		selectedProject: null,
		betaFeatures: [],
		projectPlans: [],
		assignedBetaFeatures: [],
	}),
	getters: {
		projectPlansStart() {
			return this.projectPlans.filter(plans => plans.plan == 'START');
		},
		projectPlansGrow() {
			return this.projectPlans.filter(plans => plans.plan == 'GROW');
		},
		projectPlansExpand() {
			return this.projectPlans.filter(plans => plans.plan == 'EXPAND');
		},
		projectPlansAgency() {
			return this.projectPlans.filter(plans => plans.plan == 'AGENCY');
		},
		projectPlansApionly() {
			return this.projectPlans.filter(plans => plans.plan == 'APIONLY');
		},
		selectedProjectPlan() {
			return this.projectPlans.find(plans => plans.project_id == this.selectedProject);
		},

	},
	actions: {
		async getBeta() {
			try {
				const response = await axios.post('vuejs/beta', {
					withCredentials: true,
				});

				this.betaFeatures = response.data.beta_features;
				this.projectPlans = response.data.projects_plan;
			} catch (error) {
				console.error('Error fetching beta feature:', error);
			}
		},
		async createBeta(name, description, planStart, planGrow, planExpand, planAgency) {
			const response = await axios.post(
				'vuejs/beta/create',
				{
					name: name,
					description: description,
					plan_start: planStart,
					plan_grow: planGrow,
					plan_expand: planExpand,
					plan_agency: planAgency,
				},
				{ withCredentials: true },
			);

			this.betaFeatures.push(response.data);
			this.getBeta();
		},
		async updateBeta(beta_id, name, description, status) {
			const response = await axios.post(
				'vuejs/beta/update',
				{
					beta_id: beta_id,
					name: name,
					description: description,
					status: status,
				},
				{ withCredentials: true },
			);

			this.getBeta();
		},
		async assignBeta(featureId, projects_id) {
			await axios.post(
				'vuejs/beta/assign',
				{
					beta_id: featureId,
					projects_id: projects_id,
				},
				{ withCredentials: true },
			);

			this.getBeta();
		},
		async updateBetaPlan(beta_id, planStart, planGrow, planExpand, planAgency, planApiOnly) {
			await axios.post(
				'vuejs/beta/update_plan',
				{
					beta_id: beta_id,
					plan_start: planStart,
					plan_grow: planGrow,
					plan_expand: planExpand,
					plan_agency: planAgency,
					plan_apionly: planApiOnly,
				},
				{ withCredentials: true },
			);
		},
		async updateBetaRole(beta_id, roles) {
			await axios.post(
				'vuejs/beta/update_role',
				{
					beta_id: beta_id,
					roles: roles,
				},
				{ withCredentials: true },
			);
		},
	},
	persist: true,
});
