<template>
	<div class="star-container" :title="value" v-if="value > 0 && value <= 1.5">
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
	</div>
	<div class="star-container" :title="value" v-else-if="value > 1.5 && value <= 2.5">
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
	</div>
	<div class="star-container" :title="value" v-else-if="value > 2.5 && value <= 3.5">
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
	</div>
	<div class="star-container" :title="value" v-else-if="value > 3.5 && value <= 4.5">
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--beige-fonce-fonce-semactic)" />
	</div>
	<div class="star-container" :title="value" v-else-if="value > 4.5">
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
		<Star color="var(--jaune-semactic)" />
	</div>
	<div class="star-container" v-else>No data</div>
</template>

<script setup>
import Star from './svg/star.vue';

const props = defineProps({
	value: {
		type: Number,
		required: false,
	},
});
</script>

<style lang="scss" scoped>
.star-container {
	max-width: 250px;
	display: flex;
	gap: 0.5rem;
}
</style>
