<template>
	<Skeleton height="300px" width="100%" class="card"></Skeleton>
</template>

<script setup>
import Skeleton from 'primevue/skeleton';
</script>

<style lang="scss" scoped>
.card {
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 1px solid lightgray;
	flex-grow: 1;
}
</style>
