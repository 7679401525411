<template>
	<div v-if="showModal" class="modal-backdrop" @click="closeBackdropModal">
		<div v-show="showModal" id="modal-backdrop" class="modal">
			<div class="modal-content" :style="{ 'max-width': width }" @click.stop>
				<div class="header" :style="{ 'justify-content': headerType, 'font-size': titleSize }">
					<h1 class="modal-title">
						<slot name="title">Default Title</slot>
					</h1>
					<slot name="title-data"></slot>
					<span class="close" v-if="showCloseBtn" @click="closeModal" title="Close">&times;</span>
				</div>
				<div class="modal-body" :style="{ height: height }">
					<slot name="content">Default Content</slot>
				</div>
				<div class="modal-actions">
					<slot name="actions"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref } from 'vue';

const props = defineProps({
	showModal: {
		type: Boolean,
		required: true,
	},
	backDrop: {
		type: Boolean,
		default: true,
	},
	width: {
		type: String,
		default: '800px',
	},
	height: {
		type: String,
		default: 'auto',
	},
	showCloseBtn: {
		type: Boolean,
		default: true,
	},
	headerType: {
		type: String,
		default: 'space-between',
	},
	titleSize: {
		type: String,
		default: '24px',
	},
});

const emit = defineEmits(['close-modal']);
const focusedElement = ref(null);
function closeModal() {
	emit('close-modal');
}

function closeBackdropModal() {
	if (props.backDrop && !focusedElement.value) {
		emit('close-modal');
	}
}

function focusChanged(event) {
	if (event.target.tagName === 'INPUT') {
		focusedElement.value = event.target.tagName;
	}
}

function onFocusout() {
	setTimeout(() => {
		focusedElement.value = null;
	}, 300);
}

watch(
	() => props.showModal,
	() => {
		setTimeout(() => {
			if (props.showModal) {
				const modal = document.getElementById('modal-backdrop');
				if (modal) {
					modal.addEventListener('focusin', focusChanged);
					modal.addEventListener('focusout', onFocusout);
				}
			} else {
				const modal = document.getElementById('modal-backdrop');
				if (modal) {
					modal.removeEventListener('focusin', focusChanged);
					modal.removeEventListener('focusout', onFocusout);
				}
			}
		}, 500);
	},
);
</script>

<style scoped lang="scss">
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	/* Adjusted from center to flex-start */
	/* Add some top padding to position it higher */
	z-index: 1000;
	padding-bottom: 40px;
}

.modal-content {
	position: relative;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	width: 80%;
	max-width: 800px;
	line-height: 1.5;
	/* Added interline for better readability */
	margin-top: 5vh;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	h2{
		margin: 0;
	}
}

.modal-title {
	width: 96%;
	font-size: 1.5em;
	/* Increase size of title */
	margin-bottom: 1em;
	/* Add some space below the title */
}

.modal-body {
	font-size: 1em;
	/* Adjust if necessary */
	line-height: 1.5;
	/* Interline for better readability */
	height: 100%;
	overflow: auto;
}

.close {
	position: absolute;
	top: 35px;
	right: 16px;
	background-color: #ffffff;
	color: rgb(0, 0, 0);
	font-size: 33px;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	padding: 0;
	transition: background-color 0.3s;

	&:hover {
		background-color: #f3f3f3;
		/* Slight change on hover for feedback */
	}
}

.modal-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 1rem;
}

.modal_button {
	margin: 0 2rem;
	border: 2px solid var(--gris-fonce-semactic);
	border-radius: 7px;
	padding: 0.76rem 1rem;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	background-color: #c5c5c5;
	float: left;
}

.modal_button:hover {
	background-color: #cbcccc;
}
</style>
