<template>
	<div class="title">
		<h2>{{ t('dashboard.graph_top_comp.title') }}</h2>
		<div class="select-label-box">
			<form @submit.prevent="submit" class="flex flex-row align-items-center gap-2">
				<MultiSelect v-model="selectedElement" filter :options="labels" :value="selectedLabels"
					:maxSelectedLabels="maxSelectedLabels" :selectionLimit="selectionLimit" optionLabel="label_name"
					:placeholder="t('select_labels')" class="p-graph-filter" ref="multiSelect">
					<template #option="slotProps">
						<Tag :style="{ backgroundColor: slotProps.option.color }">
							{{ slotProps.option.label_name }}
						</Tag>
					</template>
				</MultiSelect>
				<button style="margin-top: 3px" id="dashboard_top15_comp_select_labels_click">{{ t('select') }}</button>
			</form>
		</div>
	</div>
	<div class="card">
		<h1 style="text-align: center; padding-bottom: 1rem">Number of Competitor pages on the same keywords</h1>
		<!-- todo translate -->
		<div class="flex">
			<div style="width: 50%">
				<apexchart ref="topcompchart1" v-if="showGraph" type="bar" height="380" :options="chartOptions1"
					:series="series1"></apexchart>
			</div>
			<div style="width: 50%">
				<apexchart ref="topcompchart2" v-if="showGraph" type="donut" height="380" :options="chartOptions2"
					:series="series2"></apexchart>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, reactive, watchEffect, nextTick, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';

import { useMarketStore } from '@/store/global/market';
import { useGraphTop10CompStore } from '@/store/dashboard/graphTop10Comp';
import { useGraphStrengthWeaknessStore } from '@/store/dashboard/graphStrengthWeakness';

// i18n
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n({});

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const graphTop10CompStore = useGraphTop10CompStore();
const { getChartSeries, getChartXAxis, getClientUrl } = storeToRefs(graphTop10CompStore);

const graphStrengthWeaknessStore = useGraphStrengthWeaknessStore();
const { chartLabels } = storeToRefs(graphStrengthWeaknessStore);

const labels = ref([]);
const selectedLabels = ref([]);
const maxSelectedLabels = ref(4);
const selectionLimit = ref(6);

const selectedElement = ref();
const topcompchart1 = ref(null);
const topcompchart2 = ref(null);

const showGraph = ref(true);
// const chartOptions = ref();
const domain_urls = ref();
const colors = [
	'#607f6d', // Indigo
	'#1ecab8', // Turquoise
	'#fbb624', // Amber
	'#fa5c7c', // Rose
	'#994C00', // Dark Brown
	'#660066', // Purple
	'#ff7f50', // Coral
	'#bd9a5a', // Slate Blue
	'#20b2aa', // Light Sea Green
	'#ffd700', // Gold
	'#da70d6', // Orchid
	'#ff6347', // Tomato
	'#87cefa', // Light Sky Blue
	'#32cd32', // Lime Green
	'#ff69b4', // Hot Pink
	'#ba55d3', // Medium Orchid
];

const series1 = ref();
const series2 = ref();
const chartOptions1 = reactive({
	colors: colors,
	chart: {
		id: 'top10CompChartBar',
		type: 'bar',
		toolbar: {
			show: true,
		},
	},
	subtitle: {
		text: '',
		align: 'center',
		// margin: 20,
	},
	legend: {
		show: false,
	},
	xaxis: {
		categories: [],
	},
	yaxis: {
		show: true,
		labels: {
			formatter: function (value) {
				if (value === getClientUrl.value) return value.toUpperCase();
				else return value;
			},
		},
	},
	plotOptions: {
		bar: {
			borderRadius: 4,
			horizontal: true,
			distributed: true,
		},
	},
	dataLabels: {
		enabled: true,
		textAnchor: 'end',
		style: {
			colors: ['#fff'],
		},
		offsetX: 0,
		dropShadow: {
			enabled: true,
		},
	},
	tooltip: {
		enabled: true,
		followCursor: true,
		intersect: false,
		x: {
			show: false,
		},
		y: {
			title: {
				formatter: function (val, opt) {
					return opt.w.globals.labels[opt.dataPointIndex] + ':  ';
				},
			},
		},
	},
});

const chartOptions2 = reactive({
	colors: colors,
	chart: {
		id: 'top10CompChartPie',
		type: 'donut',
		toolbar: {
			show: true,
		},
	},
	legend: {
		show: true,
		formatter: function (seriesName, opts) {
			if (seriesName === getClientUrl.value) return seriesName.toUpperCase();
			else return seriesName;
		},
	},
	dataLabels: {
		enabled: true,
		textAnchor: 'end',
		style: {
			colors: ['#fff'],
		},
		offsetX: 0,
		dropShadow: {
			enabled: true,
		},
	},
	yaxis: {
		show: true,
	},
});

const submit = async () => {
	selectedLabels.value = [];
	selectedElement.value.forEach(element => {
		selectedLabels.value.push(element.label_id);
	});
	await fetchGraphData();
	topcompchart1.value.chart.updateOptions({
		xaxis: {
			categories: getChartXAxis.value,
		},
		series: getChartSeries.value,
	});
	topcompchart2.value.chart.updateOptions({
		labels: getChartXAxis.value,
		series: getChartSeries.value[0].data,
	});
};
const fetchGraphData = async () => {
	await graphTop10CompStore.fetchGraphData(selectedMarket.value, selectedProject.value, selectedLabels.value).then(() => {
		series1.value = getChartSeries.value;
		series2.value = getChartSeries.value[0].data;
		domain_urls.value = getChartXAxis.value;
		chartOptions1.xaxis.categories = getChartXAxis.value;
	});
};

await fetchGraphData();

watchEffect(() => {
	domain_urls.value = getChartXAxis.value;
	// chartOptions1.xaxis.categories = domain_urls.value;
	chartOptions2.labels = domain_urls.value;
	labels.value = chartLabels.value;
});

const multiSelect = ref(null);
// watch selectedElement for changes
// watch(selectedElement, () => {
//     nextTick(() => {
//         const selectedItems = selectedElement.value.length;
//         if (selectedItems > maxSelectedLabels.value) {
//             console.log("selectedItems", selectedItems)
//             multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = `${t("item_selected", { 'count': selectedItems })}`;
//         } else if (selectedItems === 0) {
//             multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = t('select_labels');
//         } else {
//             multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = selectedElement.value.map(e => e.label_name).join(', ');
//         }

//     });
// });
const changeMultiselect = () => {
	const selectedItems = selectedElement && selectedElement.value ? selectedElement.value.length : 0;
	if (selectedItems > maxSelectedLabels.value) {
		console.log('selectedItems', selectedItems);
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = `${t('item_selected', { count: selectedItems })}`;
	} else if (selectedItems === 0) {
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = t('select_labels');
	} else {
		multiSelect.value.$el.querySelector('div.p-multiselect-label').innerHTML = selectedElement.value.map(e => e.label_name).join(', ');
	}
};

watch(selectedElement, () => {
	nextTick(() => {
		changeMultiselect();
	});
});

onMounted(() => {
	changeMultiselect();
});
</script>

<style scoped>
.card {
	background-color: #fff;
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 2px solid #eeede3;
	min-width: 250px;
	flex-grow: 1;
}

.select-label-box {
	width: auto;
	display: flex;
	justify-content: space-between;
}

.title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-top: 1rem;

	h1{
		font-size: 1.7rem !important;
		font-weight: 500 !important;
	}

	h2{
		margin: 0.7rem 0 0.7rem 0;
		font-size: 1.4rem;
		font-weight: 500;
	}

	p {
		font-size: 0.85rem;
		font-weight: 500;
		font-style: italic;
		color: var(--gris-clair-fonce-semactic);
		line-height: 2.3rem;
	}
}

.p-graph-filter {
	float: right;
	margin-bottom: 0.3rem;
}
</style>
