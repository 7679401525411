<template>
	<div class="card" v-if="showCardEvolution">
		<p v-if="showGraphEvolution">
			<span class="font-bold">{{t('dashboard.graph_evolution.month_to_month_evolution') }}</span>
			|
			<a href="#" @click="ClicksOrSessions == 'Sessions' ? fetchSessions(selectedYear) : fetchClicks(selectedYear)" class="clickable-item">{{t('dashboard.graph_evolution.year_to_year_performance') }}</a>
		</p>
		<p v-if="showGraphClicks || showGraphSessions || showGraphRevenue || showGraphImpressions || showGraphConversions">
			<a href="#" @click="fetchData" class="clickable-item">{{t('dashboard.graph_evolution.month_to_month_evolution') }}</a> |
			<span class="font-bold">{{t('dashboard.graph_evolution.year_to_year_performance') }}</span>&nbsp;
        <span v-for="(year) in selectYearLink">
            <span v-if="year == selectedYear" class="bg-blue-100 clickable-item">{{ year }}</span>
            <span v-else-if="showGraphClicks"><a href="#" @click="fetchClicks(year)" class="clickable-item underline">{{ year }}</a></span>
            <span v-else-if="showGraphSessions"><a href="#" @click="fetchSessions(year)" class="clickable-item underline">{{ year }}</a></span>
            <span v-else-if="showGraphRevenue"><a href="#" @click="fetchRevenue(year)" class="clickable-item underline">{{ year }}</a></span>
            <span v-else-if="showGraphImpressions"><a href="#" @click="fetchImpressions(year)" class="clickable-item underline">{{ year }}</a></span>
            <span v-else-if="showGraphConversions"><a href="#" @click="fetchConversions(year)" class="clickable-item underline">{{ year }}</a></span>
        </span>
		</p>
		<apexchart v-if="showGraphEvolution" type="bar" height="320" :options="chartOptions" :series="series"
			data-cy="graph_evolution"></apexchart>
		<div v-if="showGraphClicks || showGraphSessions || showGraphRevenue || showGraphImpressions || showGraphConversions">
        <apexchart v-if="showGraphImpressions" type="bar" height="320" :options="chartImpressionsOptions"
                   :series="seriesImpressions"></apexchart>
        <apexchart v-if="showGraphClicks" type="bar" height="320" :options="chartClicksOptions"
                   :series="seriesClicks"></apexchart>
        <apexchart v-if="showGraphSessions" type="bar" height="320" :options="chartSessionsOptions"
				:series="seriesSessions"></apexchart>
        <apexchart v-if="showGraphConversions" type="bar" height="320" :options="chartConversionsOptions"
                   :series="seriesConversions"></apexchart>
        <apexchart v-if="showGraphRevenue" type="bar" height="320" :options="chartRevenueOptions"
				:series="seriesRevenue"></apexchart>
			<div class="text-center">
          <button class="gap-2 button-bg border-impressions" @click="fetchImpressions(selectedYear)"
                  :class="[showGraphImpressions ? 'bg-yellow-50' : '']">Impressions</button>
          <button class="gap-2 button-bg border-clicks" @click="fetchClicks(selectedYear)"
                  :class="[showGraphClicks ? 'bg-yellow-50' : '']">Clicks</button>
				<button class="gap-2 button-bg border-sessions" @click="fetchSessions(selectedYear)"
					:class="[showGraphSessions ? 'bg-yellow-50' : '']">Sessions</button>
          <button class="gap-2 button-bg border-conversions" @click="fetchConversions(selectedYear)"
                  :class="[showGraphConversions ? 'bg-yellow-50' : '']">Conversions</button>
          <button class="gap-2 button-bg border-revenue" @click="fetchRevenue(selectedYear)"
					:class="[showGraphRevenue ? 'bg-yellow-50' : '']">Revenue</button>
			</div>
		</div>
	</div>
  <graphEvolutionPlaceholder v-else />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useMarketStore } from '@/store/global/market';
import { storeToRefs } from 'pinia';
import { useGraphEvolutionStore } from '@/store/dashboard/graphEvolution';
import { useGraphPerformanceStore } from '@/store/dashboard/graphPerformance';
import { useDashboardStore } from '@/store/dashboard/dashboardStore';
import graphEvolutionPlaceholder from '../../components/dashboard/graphEvolutionPlaceholder.vue';
// i18n
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n({});

import * as apex_lang from '@/locales/apexchart_locales.js';
const loadLocale = async locale => {
	try {
		return apex_lang[locale];
	} catch (error) {
		console.error('An error occurred while fetching data: ', error);
		return apex_lang['en'];
	}
};

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const graphEvolutionStore = useGraphEvolutionStore();
const { additionalOptions, chartSeries, ClicksOrSessions } = storeToRefs(graphEvolutionStore);
const graphPerformanceStore = useGraphPerformanceStore();
const { additionalOptionsClicks, additionalOptionsSessions, additionalOptionsRevenue, chartSessionsSeries, chartRevenueSeries, additionalOptionsImpressions, chartImpressionsSeries, additionalOptionsConversions, chartConversionsSeries } = storeToRefs(graphPerformanceStore);
const dashboardStore = useDashboardStore();

const showCardEvolution = ref(true);
const showGraphEvolution = ref(true);
const showGraphClicks = ref(false);
const showGraphSessions = ref(false);
const showGraphRevenue = ref(false);
const showGraphImpressions = ref(false);
const showGraphConversions = ref(false);
const chartOptions = ref();
const chartClicksOptions = ref();
const chartSessionsOptions = ref();
const chartRevenueOptions = ref();
const chartImpressionsOptions = ref();
const chartConversionsOptions = ref();
const series = ref();
const seriesClicks = ref();
const seriesSessions = ref();
const seriesRevenue = ref();
const seriesImpressions = ref();
const seriesConversions = ref();
const selectYearLink = ref();
const selectedYear = ref();

const getYearsList = () => {
    const theYearList = [];

    for (let year = new Date().getFullYear(); year !== null;) {
        for (let i = 0; i < 3; i++, year = (year > 2023 ? year - 1 : null)) {
            theYearList.push(year);
        }
    }

    selectYearLink.value = theYearList;
};

const baseOptions = {
	legend: {
		offsetY: 5,
		show: true,
		showForSingleSeries: true,
	},
	chart: {
		id: 'evolutionChart',
		toolbar: {
			show: true,
			offsetY: -13,
			tools: {
				download: true,
				selection: true,
				zoom: true,
				zoomin: true,
				zoomout: true,
				pan: true,
				reset: true,
			},
			autoSelected: 'zoom',
		},
	},
	markers: {
		size: 5,
	},
	dataLabels: {
		enabled: true,
		offsetY: -12,
		style: {
			fontSize: '14px',
			fontFamily: 'Helvetica, Arial, sans-serif',
			fontWeight: 'bold',
			colors: ['#f4efef', '#f4efef', '#f4efef'],
		},
		background: {
			enabled: true,
			opacity: 0.9,
			foreColor: '#727474',
			borderColor: '#474646',
			borderRadius: 2,
			borderWidth: 1,
		},
	},
	stroke: {
		width: [3, 3, 3],
	},
	xaxis: {
		type: 'datetime',
		axisBorder: {
			show: true,
			color: '#bec7e0',
		},
		axisTicks: {
			show: true,
			color: '#bec7e0',
		},
		labels: {
        formatter: function (value) {
            const date = new Date(value);
            return date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
            });
        },
		},
	},
	grid: {
		position: 'back',
		borderColor: '#f1f3fa',
		padding: {
			left: 30,
			right: 30,
		},
	},
	title: {
		text: '',
	},
};

const baseOptionsYearToYear = {
	chart: {
		id: 'yearToYearChart',
		toolbar: {
			show: true,
			offsetY: -13,
			tools: {
				download: true,
				selection: true,
				zoom: true,
				zoomin: true,
				zoomout: true,
				pan: true,
				reset: true,
			},
			autoSelected: 'zoom',
		},
	},
	dataLabels: {
		enabled: true,
		offsetY: 0,
		style: {
			fontSize: '14px',
			fontFamily: 'Helvetica, Arial, sans-serif',
			fontWeight: 'bold',
		},
		background: {
			enabled: true,
			padding: 0,
			opacity: 0.9,
			foreColor: '#767878',
			borderColor: '#474646',
		},
	},
	stroke: {
		width: [0, 0],
	},
	xaxis: {
		type: 'category',
		axisBorder: {
			show: true,
			color: '#bec7e0',
		},
		axisTicks: {
			show: true,
			color: '#bec7e0',
		},
		tooltip: {
			enabled: true,
		},
	},
	tooltip: {
		enabled: true,
		followCursor: true,
		shared: false,
		intersect: false,
	},
	grid: {
		borderColor: '#f1f3fa',
	},
	legend: {
		offsetY: 5,
		show: true,
		showForSingleSeries: true,
	},
	responsive: [
		{
			breakpoint: 600,
			options: {
				yaxis: {
					show: false,
				},
			},
		},
	],
};

watch(ClicksOrSessions, (newValue, oldValue) => {
    chartOptions.value = {...additionalOptions.value};
    series.value = chartSeries.value;

    if (showGraphEvolution.value === false) {
        if (newValue === 'Clicks') {
            fetchClicks(selectedYear.value);
        } else if (newValue === 'Sessions') {
            fetchSessions(selectedYear.value);
        }
    }
});

const fetchData = async () => {
	await graphEvolutionStore.fetchData(selectedMarket.value, selectedProject.value).then(() => {
		chartOptions.value = Object.assign(baseOptions, additionalOptions.value);

		series.value = chartSeries.value;

		if (chartSeries.value.length === 0) {
        showCardEvolution.value = false;
			  showGraphEvolution.value = false;
		} else {
			  showGraphEvolution.value = true;
        showGraphClicks.value = false;
			  showGraphSessions.value = false;
			  showGraphRevenue.value = false;
			  showGraphImpressions.value = false;
			  showGraphConversions.value = false;
		}

      selectedYear.value = graphEvolutionStore.yearCurrent;
	});
};

await fetchData();

const fetchClicks = async (year) => {
    await graphPerformanceStore.fetchDataClicks(selectedMarket.value, selectedProject.value, year).then((result) => {
        chartClicksOptions.value = Object.assign(baseOptionsYearToYear, additionalOptionsClicks.value);
        seriesClicks.value = result;

        if (result.length === 0) {
            showGraphClicks.value = false;
            showGraphEvolution.value = true;
        } else {
            showGraphClicks.value = true;
            showGraphSessions.value = false;
            showGraphEvolution.value = false;
            showGraphRevenue.value = false;
            showGraphImpressions.value = false;
            showGraphConversions.value = false;
        }

        selectedYear.value = graphPerformanceStore.yearCurrent;
        getYearsList();
    });
};

const fetchSessions = async (year) => {
	await graphPerformanceStore.fetchDataSessions(selectedMarket.value, selectedProject.value, year).then((result) => {
		chartSessionsOptions.value = Object.assign(baseOptionsYearToYear, additionalOptionsSessions.value);
		seriesSessions.value = result;

		if (result.length === 0) {
			showGraphSessions.value = false;
			showGraphEvolution.value = true;
		} else {
			showGraphSessions.value = true;
        showGraphClicks.value = false;
			showGraphEvolution.value = false;
			showGraphRevenue.value = false;
			showGraphImpressions.value = false;
			showGraphConversions.value = false;
		}

    selectedYear.value = graphPerformanceStore.yearCurrent;
      getYearsList();
	});
};

const fetchRevenue = async (year) => {
	await graphPerformanceStore.fetchDataRevenue(selectedMarket.value, selectedProject.value, year).then((result) => {
		chartRevenueOptions.value = Object.assign(baseOptionsYearToYear, additionalOptionsRevenue.value);

		seriesRevenue.value = result;

		if (result.length === 0) {
			showGraphRevenue.value = false;
			showGraphEvolution.value = true;
		} else {
			showGraphRevenue.value = true;
			showGraphEvolution.value = false;
        showGraphClicks.value = false;
			showGraphSessions.value = false;
			showGraphImpressions.value = false;
			showGraphConversions.value = false;
		}

      selectedYear.value = graphPerformanceStore.yearCurrent;
      getYearsList();
	});
};

const fetchImpressions = async (year) => {
	await graphPerformanceStore.fetchDataImpressions(selectedMarket.value, selectedProject.value, year).then((result) => {
		chartImpressionsOptions.value = Object.assign(baseOptionsYearToYear, additionalOptionsImpressions.value);
		seriesImpressions.value = result;

		if (result.length === 0) {
			showGraphImpressions.value = false;
		} else {
			showGraphImpressions.value = true;
			showGraphEvolution.value = false;
      showGraphClicks.value = false;
			showGraphSessions.value = false;
			showGraphRevenue.value = false;
			showGraphConversions.value = false;
		}

      selectedYear.value = graphPerformanceStore.yearCurrent;
      getYearsList();
	});
};

const fetchConversions = async (year) => {
	await graphPerformanceStore.fetchDataConversions(selectedMarket.value, selectedProject.value, year).then((result) => {
		chartConversionsOptions.value = Object.assign(baseOptionsYearToYear, additionalOptionsConversions.value);
		seriesConversions.value = result;

		if (result.length === 0) {
			showGraphConversions.value = false;
		} else {
			showGraphConversions.value = true;
			showGraphImpressions.value = false;
			showGraphEvolution.value = false;
			showGraphSessions.value = false;
			showGraphRevenue.value = false;
      showGraphClicks.value = false;
		}

      selectedYear.value = graphPerformanceStore.yearCurrent;
      getYearsList();
	});
};

const chartLang = await loadLocale(locale.value);
chartOptions.value = {
	...chartOptions.value,
	chart: {
		locales: [chartLang],
		defaultLocale: locale.value,
	},
};
</script>

<style scoped>
.card {
	background-color: #fff;
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 2px solid #eeede3;
	min-width: 250px;
	flex-grow: 1;
}

.button-bg {
	background-color: #faf4d4;
}

.border-clicks {
    border: 3px solid #bd9a5a;
}

.border-sessions {
	border: 3px solid #20016c;
}

.border-revenue {
	border: 3px solid #77d0ba;
}

.border-impressions {
	border: 3px solid #fa5c7c;
}

.border-conversions {
	border: 3px solid #f3d548;
}

/* Add styles for clickable items */
.clickable-item {
	/* background-color: #e0e0e0; */
	/* Default background color */
	padding: 5px;
	border-radius: 5px;
	text-decoration: none;
	color: inherit;
}

.clickable-item:hover {
	background-color: #c0c0c0;
	/* Hover background color */
}
</style>
