<template>
	<section id="user">
		<NoticationBell @openDevLogsModal="devLogsModal = true" />
		<HelpMenu />
		<div class="profile" @click="toggleMenu">
			<avatar :href="user_image"></avatar>
			<p class="name">{{ user_name }} <i :class="['dropdown-icon', menuOpen ? 'icon-up' : 'icon-down']"></i></p>
			<ul v-if="menuOpen" class="dropdown-menu">
				<li>
					<router-link to="/edit-profile">{{ t('edit_profile') }}</router-link>
				</li>
                <featureSection :feature-name="'plan management'" :show="false">
                    <li>
    					<router-link to="/billing">{{ t('plan_management.title') }}</router-link>
    				</li>
                </featureSection>
				<li v-if="user.hasAccess('super_admin') == true">
					<router-link to="/global-settings">{{ t('app_settings') }}</router-link>
				</li>
				<li>
					<div @click="devLogsModal = true">{{ t('app_updates') }}</div>
				</li>
				<li>
					<div @click="handleLogout" id="menu_logout_click">{{ t('logout') }}</div>
				</li>
			</ul>
		</div>
		<!-- <select v-model="locale" @change="onLocaleChange">
			<option value="en">En</option>
			<option value="fr">Fr</option>
		</select> -->
		<DevsLogsModal :open="devLogsModal" @updateModal="devLogsModal = !devLogsModal" />
	</section>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import NoticationBell from '../profile/NotificationBell.vue';
import { useAuthStore } from '@/store/global/auth';
import { useProfileStore } from '@/store/global/profile.js';
import avatar from './avatar.vue';
import { storeToRefs } from 'pinia';
import HelpMenu from '@/components/utilities/profile/HelpMenu.vue';
import DevsLogsModal from '@/components/utilities/modal/DevsLogsModal.vue';
import featureSection from '@/components/utilities/featureSection.vue';
// i18n

const user = useAuthStore();
const profile = useProfileStore();
const { user_name, user_image, activity_data } = storeToRefs(profile);

// i18n
import { useI18n } from 'vue-i18n';
import VueApexCharts from 'vue-apexcharts';

const { t, locale, availableLocales } = useI18n({});

const devLogsModal = ref(false);
const router = useRouter();

const onLocaleChange = () => {
	console.log('Locale changed to: ' + locale.value);
	localStorage.setItem('locale', locale.value);
	location.reload();
};

await profile.fetchData();
let menuOpen = ref(false);

onMounted(() => {
	document.addEventListener('click', closeMenu);
	//   localStorage.getItem('locale') ? locale.value = localStorage.getItem('locale') : locale.value = 'en';
});

async function toggleMenu() {
	menuOpen.value = !menuOpen.value;
}

function closeMenu(event) {
	if (!event.target.closest('.profile')) {
		menuOpen.value = false;
	}
}

const handleLogout = () => {
	localStorage.setItem('logout', 'true');
	// Navigate to logout route programmatically
	router.push('/logout');
};
</script>

<style lang="scss" scoped>
#user {
	display: flex;
	align-items: center;

    .plans-icon{
        cursor: pointer;
        width: 34px;
        height: 34px;
    }

    .plans-icon2{
        cursor: pointer;
        width: 44px;
        height: 44px;
    }

	.profile {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		.name {
			font-weight: 600;
			margin-right: 0.5rem;
			position: relative;
			display: inline-block;
		}

		.dropdown-menu {
			position: absolute;
			top: 2.5rem;
			left: 0;
			width: 100%;
			list-style: none;
			padding: 0;
			margin: 0;
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 9px;
			z-index: 999;

			li {
				padding: 6px 1rem;

				&:first-child {
					border-radius: 9px 9px 0 0;
				}

				&:last-child {
					border-radius: 0 0 9px 9px;
				}

				&:hover {
					background-color: #e6f7ff;
				}

				a {
					width: 100%;
					list-style: none;
					display: block;
					text-decoration: none;
				}
			}
		}
	}
}
</style>
