<template>
	<header class="bg-red">
		<div class="flex">
			<Skeleton width="280px" height="43.25px" borderRadius="999px" class="skelet mr"></Skeleton>
			<Skeleton width="140px" height="43.25px" borderRadius="999px" class="skelet mr"></Skeleton>
		</div>

		<section id="user">
			<Skeleton shape="circle" size="36px" class="skelet mr"></Skeleton>
			<Skeleton width="90px" height="23px" borderRadius="8px" class="skelet"></Skeleton>
		</section>
	</header>
</template>

<script setup>
import Skeleton from 'primevue/skeleton';
</script>
<style lang="scss" scoped>
.skelet {
	border: 1px solid lightgray;
}

.mr {
	margin-right: 1.5rem;
}
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding: 1.7rem 0 1rem;
}

.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

#user {
	display: flex;
	align-items: center;

	p {
		font-weight: 600;
	}
}
</style>
