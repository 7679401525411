// ####################################################################################################################
// # Copyright (C) 2023 Semactic SRL - All Rights Reserved
// # Unauthorized copying of this file, or its contents, even partially, through any medium is strictly prohibited.
// # Proprietary and confidential. Semactic SRL is the sole owner of this code.
// # Written by Fourneaux Thibaut (thibaut.fourneaux@semactic.com) under Semactic SEO Software Project.
// ####################################################################################################################

import { defineStore } from 'pinia';
import axios from 'axios';

export const useRecommendationDetailStore = defineStore('recommendation-detail', {
	state: () => ({
		recommendation: {},
		graphRange: {
			from: null,
			to: null,
		},
		implementations: [],
		keyword: {
			id: null,
			name: '',
			volume: null,
			potential: null,
			position: null,
		},
		relatedKeywords: [],
		loading: true,
		notificationMarketUsers: [],
		notificationCustomerServiceUsers: [],
		commentId: null,
	}),
	getters: {
		implementedAuto: (state) => {
			return state.implementations.filter(impl => impl.auto === 1);
		},
		implementedManual: (state) => {
			return state.implementations.filter(impl => impl.manual === 1);
		},
	},
	actions: {
		async fetchData(recoId, marketId = null) {
			this.loading = true;
			this.recommendation = {};
			this.keyword = {
				id: null,
				name: '',
				volume: null,
				potential: null,
				position: null,
			};
			if (recoId == 0 && marketId) {
				const response = await axios.post(
					'vuejs/get_recommendations_lv1_detail',
					{
						market_id: marketId,
					},
					{ withCredentials: true },
				);

				this.recommendation = response.data.data;
				this.keyword.id = this.recommendation.kw_id != 0 ? this.recommendation.kw_id : null;
			} else {
				const response = await axios.post(
					'vuejs/get_recommendations_lv1_detail',
					{
						reco_id: recoId,
					},
					{ withCredentials: true },
				);

				this.recommendation = response.data.data;
				this.keyword.id = this.recommendation.kw_id != 0 ? this.recommendation.kw_id : null;
			}

			this.getRecommendationKeyword(this.keyword.id);
			this.loading = false;
		},
		async getImplementationAndDates(groupId, keywordId = null) {
			this.implementations = [];

			const response = await axios.post(
				'vuejs/get_graph_implementations',
				{
					group_id: groupId,
					keyword_id: keywordId,
				},
				{ withCredentials: true },
			);

			this.implementations = response.data.data.implementation || [];
			this.graphRange.from = response.data.data.date_range_start || null;
			this.graphRange.to = response.data.data.date_range_end || null;
		},
		async getRecommendationKeyword(keywordId) {
			const response = await axios.post(
				'vuejs/get_reco_keyword',
				{
					keyword_id: keywordId,
				},
				{ withCredentials: true },
			);

			this.keyword = {
				id: keywordId,
				name: response.data.data.keyword,
				volume: response.data.data.volume,
				potential: response.data.data.seo_potential,
				position: response.data.data.client_position,
			};
		},
		async setPageUrl(recoId, url, doneToCheck) {
			const data = {};

			await axios
				.post(
					'vuejs/set_reco_page_url',
					{
						reco_id: recoId,
						url: url,
						done_to_check: doneToCheck,
					},
					{ withCredentials: true },
				)
				.then(function (response) {
					data.success = true;
					data.error_code = response.data.error_code;
				})
				.catch(function (error) {
					data.success = false;
					data.error_code = error.response.data.error_code;
				});

			if (data.success) {
				let reco = this.recommendation.tasks.filter(task => task.id == recoId)[0];

				reco.page_url = url;
				reco.validated_type = 'auto';
				reco.implemented = true;
			}

			return data;
		},
		async setImplementation(recoId) {
			const response = await axios.post(
				'vuejs/set_reco_implemented',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].implemented = true;
				this.recommendation.tasks.filter(task => task.id == recoId)[0].validated_type = 'manual';
			}
		},
		async undoImplementation(recoId) {
			const response = await axios.post(
				'vuejs/undo_reco_implemented',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].implemented = false;
			}
		},
		async setValidation(recoId) {
			const response = await axios.post(
				'vuejs/set_reco_validated',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].validated = true;
			}
		},
		async undoValidation(recoId) {
			const response = await axios.post(
				'vuejs/undo_reco_validated',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].validated = false;
			}
		},
		async setAbandonment(recoId) {
			const response = await axios.post(
				'vuejs/set_reco_abandoned',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].abandoned = true;
			}
		},
		async undoAbandonment(recoId) {
			const response = await axios.post(
				'vuejs/undo_reco_abandoned',
				{
					reco_id: recoId,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].abandoned = false;
			}
		},
		async updateMessage(recoId, message) {
			const response = await axios.post(
				'vuejs/update_reco_message',
				{
					reco_id: recoId,
					message: message,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].message = message;
			}
		},
		async updatePriority(recoId, priority) {
			const response = await axios.post(
				'vuejs/update_reco_priority',
				{
					reco_id: recoId,
					priority: priority,
				},
				{ withCredentials: true },
			);

			if (response.data.success) {
				this.recommendation.tasks.filter(task => task.id == recoId)[0].priority = priority;
			}
		},
		async getRelatedKeywords(keyword_id) {
			this.relatedKeywords = [];
			if (this.recommendation.task_type == 'New content') {
				const response = await axios.post(
					'vuejs/get_related_kw',
					{
						keyword_id: keyword_id,
					},
					{ withCredentials: true },
				);
				this.relatedKeywords = response.data.data;
			}
		},
		async createRelatedKeyword(market_id, keyword, kw_id) {
			const response = await axios.post(
				'vuejs/create_related_kw',
				{
					market_id: market_id,
					keyword: keyword,
					kw_id: kw_id,
				},
				{ withCredentials: true },
			);

			this.getRelatedKeywords(this.keyword.id);
			return response.data;
		},

		// async createRelatedKeyword(market_id, keyword, kw_id) {

		//     await axios.post("vuejs/keyword_finder", {
		//         market_id: market_id,
		//         query: query,
		//         type: type,
		//         language: lang,
		//         country: country
		//     },{ withCredentials: true })

		//     this.getRelatedKeywords(this.keyword.id);
		//     return response.data;
		// },

		async deleteRelatedKeyword(id, market_id) {
			const response = await axios.post(
				'vuejs/delete_related_kw',
				{
					id: id,
					market_id: market_id,
				},
				{ withCredentials: true },
			);

			this.getRelatedKeywords(this.keyword.id);
			return response.data;
		},

		async sendNotification(taskId, recoId) {
			let message = this.recommendation.tasks.filter(task => task.id == taskId)[0].comment;

			const response = await axios.post(
				'vuejs/send_comment_notification',
				{
					reco_id: recoId,
					task_id: taskId,
					message: message,
				},
				{ withCredentials: true },
			);

			return response.data;
		},

		async getUserGroupNotification(market_id, reco_id) {
			const response = await axios.post(
				'vuejs/get_user_group_notification',
				{
					market_id: market_id,
					reco_id: reco_id,
				},
				{ withCredentials: true },
			);

			this.notificationMarketUsers = response.data.users_market;
			this.notificationCustomerServiceUsers = response.data.users_customer_service;
		},

		async storeUserGroupNotification(reco_id, market_id, selected_users) {
			const response = await axios.post(
				'vuejs/store_user_group_notification',
				{
					reco_id: reco_id,
					market_id: market_id,
					selected_users: selected_users,
				},
				{ withCredentials: true },
			);
		},

		async scrapWebsite(url) {
			const response = await axios.post(
				'vuejs/scrap_website_4_editor',
				{
					url: url,
				},
				{ withCredentials: true },
			);

			console.log(response.data);
			return response.data.response;
		},

		async getTaskDetails(url, element_to_check, market_id) {
			this.detailedTask = [];

			const response = await axios.post(
				'vuejs/get_task_details',
				{
					market_id: market_id,
					url: url,
					element_to_check: element_to_check,
				},
				{ withCredentials: true },
			);

			this.detailedTask = response.data.reco_details;
		},

		async getProjectsJira(marketId) {
			const response = await axios.post(
				'vuejs/jira_api',
				{
					market_id: marketId,
					action: 'get_projects'
				},
				{ withCredentials: true },
			);

			return response.data;
		},

		async getBoardsMonday(marketId) {
			const response = await axios.post(
				'vuejs/monday_api',
				{
					market_id: marketId,
					action: 'get_boards'
				},
				{ withCredentials: true },
			);

			return response.data;
		},

		async createIssueJira(taskId, projectId, marketId) {
			const response = await axios.post(
				'vuejs/jira_api',
				{
					task_id: taskId,
					project_id: projectId,
					market_id: marketId,
					action: 'create_issue'
				},
				{ withCredentials: true },
			);

			return response.data;
		},

		async createIssueMonday(taskId, boardId, marketId) {
			const response = await axios.post(
				'vuejs/monday_api',
				{
					task_id: taskId,
					board_id: boardId,
					market_id: marketId,
					action: 'create_item'
				},
				{withCredentials: true},
			);

			return response.data;
		},

		async updateTitle(recoId, title) {
			const response = await axios.post(
				'vuejs/update_reco_1_title', {
					reco_id: recoId,
					title: title
				},
				{ withCredentials: true },
			);

			return response.data;
		},

		async updateDescription(recoId, description) {
			const response = await axios.post(
				'vuejs/update_reco_1_description', {
					reco_id: recoId,
					description: description
				},
				{ withCredentials: true },
			);

			return response.data;
		},
	},
	persist: true,
});
