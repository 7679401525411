<template v-if="show_container">
	<Modal width="1200px" height="45vh" :showModal="modalTrends" @close-modal="modalTrends = false"
		id="dashboard_show_label_trends_click">
		<template v-slot:title>
			<div>
				<h3>{{ t('theme_analysis.search_volume_title') }} {{ labelName }}</h3>
			</div>
		</template>
		<template v-slot:content>
			<div v-if="loading_trends" class="col-12 mt-8" style="height: 80%; display: inline-flex">
				<div class="col-5">&nbsp;</div>
				<div class="col-2 pt-8">
					<i class="pi pi-spin pi-spinner px-5" style="font-size: 3rem; margin-left: 10px"></i>
				</div>
				<div class="col-5">&nbsp;</div>
			</div>
			<apexchart v-else ref="chart" height="400" type="bar" :options="gsChartOptions" :series="gsSeries">
			</apexchart>
		</template>
	</Modal>
	<div class="title">
		<h2>{{ t('theme_analysis.title') }}</h2>
	</div>
	<div class="card">
		<div class="flex flex-wrap gap-4 pr-3 pb-3 text-1xl font-medium" style="color: grey">
			<div class="flex align-items-center">
				<!-- TODO REPLACE RADIO WITH BUTTON -->
				<RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter1" name="top" value="10"
					id="dashboard_theme_top10_click" />
				<label class="ml-2">Top 10</label>
			</div>
			<div class="flex align-items-center">
				<RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter2" name="top" value="5"
					id="dashboard_theme_top5_click" />
				<label class="ml-2">Top 5</label>
			</div>
			<div class="flex align-items-center">
				<RadioButton v-model="topFilter" @change="fetchData" inputId="topFilter3" name="top" value="3"
					id="dashboard_theme_top3_click" />
				<label class="ml-2">Top 3</label>
			</div>
		</div>
		<div class="flex">
			<div id="tableContainer">
				<div id="themeAnalysisTable" style="width: 61rem">
					<DataTable :value="theme_table_data" sortField="volume" :sortOrder="-1" tableStyle="width: 59rem"
						scrollable scrollHeight="540px">
						<template #empty>
							<h2 class="flex justify-content-center p-5 text-2xl">No data</h2>
						</template>
						<Column field="label" :header="t('theme_analysis.col1')" sortable filterField="label"
							:showFilterMatchModes="false" :showFilterOperator="false" style="max-width: 70px" frozen>
							<template #body="slotProps">
								<Tag @click="showTrendsByLabel(slotProps.data.label, slotProps.data.label_id)"
									:title="t('theme_analysis.trends_title')"
									:style="{ ...getTagStyle(slotProps.data.color), cursor: 'pointer' }">{{
										slotProps.data.label }}</Tag>
							</template>
							<template #filter="{ filterModel }">
								<MultiSelect v-model="filterModel.value" :options="labelSet" optionLabel="name"
									placeholder="Any" :filter="true" :maxSelectedLabels="5" class="p-column-filter">
									<template #option="slotProps">
										<Tag :style="{ backgroundColor: slotProps.option.color }"
											:data-cy="`label-filter-${slotProps.option.name}`">
											{{ slotProps.option.name }}
										</Tag>
									</template>
								</MultiSelect>
							</template>
						</Column>
						<Column field="volume" sortable dataType="numeric" :header="t('theme_analysis.col2')"
							style="text-decoration: underline; max-width: 40px; cursor: pointer" frozen>
							<template #filter="{ filterModel }">
								<InputNumber v-model="filterModel.value" data-cy="volume-filter" />
							</template>
						</Column>
						<Column field="keywords_top" :header="keywords_top_title" style="max-width: 60px" frozen>
							<template #body="slotProps">
								<div style="display: flex">
									<div>{{ slotProps.data.keywords_top_right }}</div>
									<comparatorTag :position="slotProps.data.keywords_top_left"
										:last_position="slotProps.data.keywords_top_right" />
								</div>
							</template>
							<template #filter="{ filterModel }">
								<InputNumber v-model="filterModel.value" data-cy="keywords_top-filter" />
							</template>
						</Column>
						<Column field="percent_top" sortable :header="percent_top_title" style="max-width: 90px" frozen>
							<template #body="slotProps"> {{ slotProps.data.percent_top }}% </template>
							<template #filter="{ filterModel, filterCallback }">
								<Slider v-model="filterModel.value" @change="filterCallback()" range class="m-3"
									data-cy="percent_top-filter"></Slider>
								<div class="flex align-items-center justify-content-between p-2">
									<span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
									<span>{{ filterModel.value ? filterModel.value[1] : 101 }}</span>
								</div>
							</template>
						</Column>
						<Column field="keywords_total" sortable :header="t('theme_analysis.col5')"
							style="max-width: 60px" frozen>
							<template #filter="{ filterModel }">
								<InputNumber v-model="filterModel.value" data-cy="keywords_total-filter" />
							</template>
						</Column>
						<!--                    <Column field="trends" :header="t('theme_analysis.col6')" style="max-width: 80px" frozen>-->
						<!--                        <template #body='{data}'>-->
						<!--                            <apexchart-->
						<!--                                width="120"-->
						<!--                                height="60px"-->
						<!--                                type="line"-->
						<!--                                :options="trendsOptions"-->
						<!--                                :series="data.trendsSeries"-->
						<!--                                @click="showTrends"-->
						<!--                            ></apexchart>-->
						<!--                        </template>-->
						<!--                    </Column>-->
					</DataTable>
				</div>
				<div class="chart">
					<apexchart type="pie" width="650" :options="themeChartOptions" :series="theme_series"></apexchart>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterService, FilterMatchMode, FilterOperator } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';
import comparatorTag from '../visibility/table/comparatorTag.vue';
import VueApexCharts from 'vue-apexcharts';
import Modal from '@/components/utilities/modal/modal.vue';

import { useMarketStore } from '@/store/global/market';
import { useGraphThemeAnalysisStore } from '@/store/dashboard/graphThemeAnalysis';
//i18n
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { getTagStyle } from '@/utils/componentsStyle';

const marketStore = useMarketStore();
const { selectedProject, selectedMarket } = storeToRefs(marketStore);
const graphThemeAnalysisStore = useGraphThemeAnalysisStore();
const { getTableData, getChartSeries, getChartColors, getChartXAxis, filter_top, labelSet } = storeToRefs(graphThemeAnalysisStore);

const show_container = ref(true);
const theme_table_data = ref();
const theme_series = ref();
const topFilter = ref('10');
const keywords_top_title = ref();
const percent_top_title = ref();
const filters = ref();

const initFilters = () => {
	filters.value = {
		label: { value: null, matchMode: 'containsAny' },
		volume: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
		},
		keywords_top_right: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
		},
		percent_top: { value: [0, 101], matchMode: FilterMatchMode.BETWEEN },
		keywords_total: {
			operator: FilterOperator.AND,
			constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
		},
	};
};

FilterService.register('containsAny', (value, filter) => {
	if (filter === undefined || filter === null || filter.length === 0) {
		return true;
	}

	if (value === undefined || value === null) {
		return false;
	}

	return filter.some(f => f.name === value);
});

initFilters();

const clearFilter = () => {
	initFilters();
};

const themeChartOptions = ref({
	colors: [],
	chart: {
		id: 'ThemeAnalysisChart',
		toolbar: {
			show: true,
		},
	},
	legend: {
		show: true,
	},
	dataLabels: {
		enabled: true,
		textAnchor: 'end',
		style: {
			colors: ['#fff'],
		},
		offsetX: 0,
		dropShadow: {
			enabled: true,
		},
	},
	yaxis: {
		show: true,
	},
	labels: [],
});

const fetchData = async () => {
	await graphThemeAnalysisStore.fetchData(selectedMarket.value, selectedProject.value, topFilter.value).then(() => {
		if (getTableData.value.length > 0) {
			theme_table_data.value = getTableData.value;
			keywords_top_title.value = t('theme_analysis.col3').replace(/top_value/g, filter_top.value);
			percent_top_title.value = t('theme_analysis.col4').replace(/top_value/g, filter_top.value);
			theme_series.value = getChartSeries.value;
			themeChartOptions.value.labels = getChartXAxis.value;
			themeChartOptions.value.colors = getChartColors.value;
		} else {
			show_container.value = false;
		}
	});
};

await fetchData();

// let trendsOptions = ref({
//     chart: {
//         type: "area",
//         stacked: false,
//         height: 30,
//         toolbar: {
//             show: false,
//         }
//     },
//     noData: {
//         text: 'No data',
//         align: 'center',
//         verticalAlign: 'middle',
//     },
//     grid: {
//         yaxis: {
//             lines: {
//                 show: false,
//             },
//         },
//     },
//     dataLabels: {
//         enabled: false,
//     },
//     yaxis: {
//         labels: {
//             show: false,
//         },
//     },
//     xaxis: {
//         labels: {
//             show: false,
//         },
//         lines: {
//             show: false,
//         },
//         type: "datetime",
//     },
//     tooltip: {
//         show: false,
//     },
// });
//
// let tableData = ref([]);
//
// watchEffect(() => {
//     tableData = graphThemeAnalysisStore.trends;
//
//     tableData.forEach((elem, index) => {
//         elem.trendsSeries = [
//             {
//                 name: `series${index+1}`,
//                 data:[]
//             }
//         ];
//
//         if (elem.trends.length > 0) {
//             elem.trends.forEach(e => {
//                 elem.trendsSeries[0].data.push([new Date( e.year, e.month ,1).getTime(), e.search_volume])
//             });
//         }
//     });
// });

const gsChartOptions = ref({
	chart: {
		type: 'bar',
		stacked: false,
		toolbar: {
			show: true,
			tools: {
				download: true,
				selection: true,
				zoom: true,
				zoomin: true,
				zoomout: true,
				pan: true,
				reset: true,
			},
			autoSelected: 'zoom',
			events: {
				beforeResetZoom: function (chartContext, opts) { },
			},
		},
	},
	stroke: {
		curve: 'smooth',
	},
	tooltip: {
		enabled: true,
		x: {
			show: true,
			format: 'MMMM',
			formatter: undefined,
		},
	},
	xaxis: {
		type: 'datetime',
		axisBorder: {
			show: true,
			color: '#bec7e0',
		},
		axisTicks: {
			show: true,
			color: '#bec7e0',
		},
		labels: {
			show: true,
			rotate: -45,
			minHeight: 72,
			rotateAlways: true,
			hideOverlappingLabels: false,
			showDuplicates: false,
			offsetX: 0,
			datetimeFormatter: {
				year: 'yyyy',
				month: "MMM 'yy",
				day: 'dd MMM',
			},
		},
	},
	noData: {
		text: t('no_data'),
	},
});

const gsSeries = ref([]);
const modalTrends = ref(false);
const labelName = ref('');
const loading_trends = ref(true);

// const showTrends = (event, chartContext, config) => {
//     labelName.value = "";
//     modalTrends.value = true;
//     gsSeries.value = [
//         {
//             name: "Search Volume",
//             data: config.globals.initialSeries[0].data
//         }
//     ]
// }

const showTrendsByLabel = async (label_name, label_id) => {
	loading_trends.value = true;
	modalTrends.value = true;

	let d = [];

	await graphThemeAnalysisStore.getLabelTrend(selectedMarket.value, label_id).then(() => {
		let raw = graphThemeAnalysisStore.trends;

		if (!raw.length) {
			notification.open('error', t('theme_analysis.trends_loading_error'));
			return false;
		}

		raw.forEach(elem => {
			d.push([new Date(elem.year, elem.month - 1, 2).getTime(), elem.search_volume]);
		});

		labelName.value = '"' + label_name + '"';
		gsSeries.value = [
			{
				name: 'Search Volume',
				data: d,
			},
		];

		loading_trends.value = false;
	});
};
</script>

<style lang="scss" scoped>
.card {
	background-color: #fff;
	border-radius: 1rem;
	padding: 1.5rem 0.5rem 1rem;
	border: 2px solid #eeede3;
	min-width: 250px;
	flex-grow: 1;
}

.title {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-top: 1rem;

	h1{
		font-size: 1.7rem;
		font-weight: 500;
	}

	h2{
		margin: 0.7rem 0 0.7rem 0;
		font-size: 1.4rem;
		font-weight: 500;
	}

	p {
		font-size: 0.85rem;
		font-weight: 500;
		font-style: italic;
		color: var(--gris-clair-fonce-semactic);
		line-height: 2.3rem;
	}
}

#tableContainer {
	display: inline-flex;
}

@media (max-width: 1800px) {
	#tableContainer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		#themeAnalysisTable {
			max-width: 70rem !important;
		}
	}

	.chart {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2rem;
	}
}

@media (max-width: 1850px) {
	#tableContainer {
		width: 100%;

		#themeAnalysisTable {
			max-width: 42rem;
		}
	}

	.chart {
		margin-top: 2rem;
	}
}
</style>
