export function orderArray(array, name = 'reco') {
	if (array.length === 0) {
		return [];
	}

	array = orderItems(array, name);

	return array;
}

function orderItems(array, name) {
	const nonNullItems = array.filter(item => item.position !== null && item.position !== undefined);
	const nullItems = array.filter(item => item.position === null || item.position === undefined);

	nonNullItems.sort((a, b) => {
		const posA = parseFloat(a.position);
		const posB = parseFloat(b.position);
		return posA - posB;
	});

	nullItems.forEach((item, index) => {
		item.position = nonNullItems.length + index + 1.0;
	});

	return nonNullItems.concat(nullItems);
}

function mapIdAsKey(array) {
	let result = {};
	array.forEach(item => {
		result[item.id] = item;
	});
	return result;
}

export function setBeforeAfter(array, name = 'reco') {
	if (array.length === 1) {
		array[0][name + '_id_before'] = 0;
		array[0][name + '_id_after'] = 0;
	} else {
		for (let i = 0; i < array.length; i++) {
			const currentObj = array[i];
			const previousObj = array[i - 1] || null;
			const nextObj = array[i + 1] || null;

			currentObj[name + '_id_before'] = previousObj ? previousObj.id : 0;
			currentObj[name + '_id_after'] = nextObj ? nextObj.id : 0;

			// Convert the values to integers
			currentObj[name + '_id_before'] = parseInt(currentObj[name + '_id_before']);
			currentObj[name + '_id_after'] = parseInt(currentObj[name + '_id_after']);
		}
	}

	return array;
}

export function checkForChange(initialArray, finalArray) {
	let changedElements = [];

	if (initialArray.length !== finalArray.length) {
		throw new Error('Arrays must have the same length for comparison.');
	}

	initialArray = mapIdAsKey(initialArray);
	finalArray = mapIdAsKey(finalArray);

	for (const key in initialArray) {
		if (initialArray.hasOwnProperty(key)) {
			const initialObj = initialArray[key];
			const finalObj = finalArray[key];

			if (!isEqual(initialObj, finalObj)) {
				changedElements.push(finalObj);
			}
		}
	}
	return changedElements;
}

function isEqual(objA, objB) {
	return JSON.stringify(objA) === JSON.stringify(objB);
}

export function updatePosition(array, addedIndex, removedIndex, recoId = null) {
	let item = null;

	// move item
	if (recoId) {

		item = array.find(item => item.id === recoId);
		let itemIndex = array.findIndex(element => element.id === item.id);
		array.splice(itemIndex, 1);
		array.splice(addedIndex, 0, item);

		item = array.find(item => item.id === recoId);
		itemIndex = array.findIndex(element => element.id === item.id);
		console.log(itemIndex);
	}
	else {

		item = array[removedIndex];
		array.splice(removedIndex, 1);
		array.splice(addedIndex, 0, item);
	}

	// get neighbors after moving
	let itemBefore = addedIndex > 0 ? array[addedIndex - 1] : null;
	let itemAfter = addedIndex < array.length - 1 ? array[addedIndex + 1] : null;

	// change position of item
	if (itemBefore && itemAfter) {
		item.position = (itemBefore.position + itemAfter.position) / 2;
	}
	else if (itemBefore) {
		item.position = itemBefore.position + 1;
	}
	else if (itemAfter) {
		item.position = itemAfter.position - 1;
	}

	return array;
}