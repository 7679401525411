<template>
	<div class="card">
		<img :src="href" alt="avatar" class="avatar" />
	</div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
	href: {
		type: String,
		required: true,
	},
});
</script>

<style scoped>
.card {
	width: 36px;
	aspect-ratio: 1/1;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 0.7rem;
	margin-left: 0.7rem;
}
.card img {
	width: 100%;
	aspect-ratio: 1 /1;
	object-fit: cover;
	transition: 0.4s;
}
</style>
