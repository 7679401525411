<template>
    <div class="card text-center py-5">
        <p>{{t('dashboard.graph_evolution.graph_placeholder')}}</p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t, locale, availableLocales } = useI18n();
</script>

<style lang="scss" scoped>
.card {
    border-radius: 1rem;
    padding: 1.5rem 0.5rem 1rem;
    border: 1px solid lightgray;
    flex-grow: 1;
}
</style>